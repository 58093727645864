<template>
  <section class="lh-Faq">
    <div class="lh-Wrapper">
      <div class="lh-Wrapper-box lh-Wrapper-box--big lh-Wrapper-box--dark">
        <div class="lh-Wrapper-boxContent">
          <div class="lh-Accordion-bg" name="faq"></div>
        </div>
      </div>
      <div class="lh-Wrapper-box lh-Wrapper-box--small">
        <div class="lh-Wrapper-boxContent">
          <div class="js-lh-Accordion">
            <ul class="lh-Accordion-list">
              <li
                class="lh-Accordion-listItem js-lh-Accordion-listItem"
                v-for="item in items"
                :key="item.svg"
              >
                <button
                  class="lh-Accordion-button"
                  :class="item.isActive ? 'lh-Accordion-button--active' : ''"
                  @click="expand(item)"
                  v-html="item.title"
                ></button>
                <transition
                  name="lh-Accordion-item"
                  @enter="startTransition"
                  @after-enter="endTransition"
                  @before-leave="startTransition"
                  @after-leave="endTransition"
                >
                  <div class="lh-Accordion-content" v-if="item.isActive">
                    <div class="lh-Accordion-inner" v-html="item.text"></div>
                  </div>
                </transition>
              </li>
            </ul>
          </div>
          <div class="lh-LinkToContainer">
            <router-link class="lh-Button" to="/apropos"
              >En savoir plus</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default {
  name: "faq",
  data: () => {
    return {
      items: [
        {
          title: "Puis-je personnaliser la couleur de ma LHight&nbsp;?",
          text: `
            <p>Il n’est pour l’instant pas possible d’imprimer la lampe dans d’autres couleurs que celles proposées. Vous pourrez néanmoins, dans un futur proche, <a href="/contact">me contacter</a> pour me soumettre vos souhaits. Je vous tiendrai au courant lorsque ce sera possible.</p>
          `,
          isActive: false,
        },
        {
          title:
            "Le hashtag à l’arrière du socle et le message qui figure sur l’abat jour sont-ils personnalisables&nbsp;?",
          text: `
            <p>Il n’est pour l’instant pas possible de customiser les messages présents sur LHight. Vous pourrez néanmoins, dans un futur proche, <a href="/contact">me contacter</a> pour me soumettre vos souhaits. Je vous répondrai dans les meilleurs délais.</p>
          `,
          isActive: false,
        },
        {
          title: "Puis-je changer moi-même l’orientation des mains&nbsp;?",
          text: `
            <p>Il existe deux versions: 0 et 1 où l’orientation des mains diffère. Une fois acheté, il n’est pas possible de changer le sens de la main qui forme le H.</p>
          `,
          isActive: false,
        },
        {
          title: "Puis-je changer l’orientation de l’abat-jour&nbsp;?",
          text: `
            <p>Oui sans problème, l’abat jour, contrairement aux mains, peut tourner sur lui-même quelle que soit la version.</p>
          `,
          isActive: false,
        },
        {
          title: "Quelles sont les ampoules compatibles avec LHight&nbsp;?",
          text: `
            <p>Toutes les ampoules avec un culot E14 sont compatibles. Cependant, je vous conseille vivement d’utiliser des LED ; les autres pourraient chauffer et risqueraient de déformer l’abat-jour. Par ailleurs, il est tout à fait possible d’installer une LED multicolore.</p>
          `,
          isActive: false,
        },
        {
          title: "LHight est-elle écologique&nbsp;?",
          text: `
            <p>L’impression 3D d’LHight n’est pas encore possible sans plastique. Néanmoins, je fais tout mon possible pour choisir mes bobines selon leurs caractéristiques environnementales. Je m’efforce d’améliorer ma technique d’impression pour réduire la quantité de plastique inutile pour chaque lampe.</p>
          `,
          isActive: false,
        },
        {
          title: "J’ai cassé ma LHight, que faire&nbsp;?",
          text: `
            <p>Ah bah non ! C’est trop triste… Vous pouvez <a href="/contact">me contacter</a> et on essaiera de trouver une solution ensemble ;)</p>
          `,
          isActive: false,
        },
      ],
    };
  },
  mounted() {
    this.animItem();
  },
  methods: {
    expand(item) {
      if (item.isActive) {
        item.isActive = false;
      } else {
        this.items.map((item) => (item.isActive = false));
        item.isActive = true;
      }
    },

    startTransition(el) {
      el.style.height = el.scrollHeight + "px";
    },

    endTransition(el) {
      el.style.height = "";
    },
    animItem() {
      gsap.to(".js-lh-Accordion-listItem", {
        autoAlpha: 0,
        y: "+=20px",
      });
      ScrollTrigger.batch(".js-lh-Accordion-listItem", {
        start: "top 90%",
        onEnter: (batch) =>
          gsap.to(batch, { autoAlpha: 1, y: 0, stagger: 0.2 }),
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/components/faq/_index.scss";
</style>
