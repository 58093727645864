<template>
  <section class="lh-ProductLinked">
    <div class="lh-Wrapper">
      <div class="lh-Wrapper-box lh-Wrapper-box--full">
        <div
          class="lh-Wrapper-boxContent lh-Wrapper-boxContent--relative lh-Wrapper-boxContent--noPadding"
        >
          <swiper
            ref="swiper"
            :cssMode="true"
            :options="swiperOption"
            @slideChange="slideChanged"
            class="lh-ProductLinked-gallery swiper-container"
          >
            <swiper-slide
              class="lh-ProductList-listItem js-lh-ProductList-listItem"
              v-for="(product, index) in computedProducts"
              :key="product.id"
            >
              <router-link
                class="lh-ProductList-listItemLink"
                :to="{ name: 'product', params: { id: product.id } }"
              >
                <div class="lh-ProductList-topMobile">
                  <div class="lh-ProductList-lampPart">
                    <picture class="lh-ProductList-lampPartPicture">
                      <source
                        :srcset="
                          makeWebpImagePath(
                            product,
                            product.isNightOn && product.isGlowNight
                          )
                        "
                        type="image/webp"
                      />
                      <source
                        :srcset="
                          makePngImagePath(
                            product,
                            product.isNightOn && product.isGlowNight
                          )
                        "
                        type="image/jpeg"
                      />
                      <img
                        class="lh-ProductList-lampPartImg"
                        :alt="'Photo lampe LHight #' + product.id"
                        :data-src="
                          makePngImagePath(
                            product,
                            product.isNightOn && product.isGlowNight
                          )
                        "
                        loading="lazy"
                      />
                    </picture>
                    <div
                      class="lh-ProductList-lampPartBg"
                      :class="
                        product.isNightOn && product.isGlowNight
                          ? 'lh-ProductList-lampPartBg--dark'
                          : ''
                      "
                    >
                      <button
                        arial-label="voir dans la nuit"
                        v-if="product.isGlowNight"
                        class="lh-ProductList-toggleNight"
                        @click="toggleNight($event, product)"
                      >
                        <svg class="lh-ProductList-toggleNightSvg">
                          <use xlink:href="#night"></use>
                        </svg>
                      </button>
                    </div>
                  </div>
                  <ul
                    class="lh-ProductList-bgList js-lh-ProductList-bgList"
                    :data-isodd="isOdd(index)"
                    :data-colorscount="product.colors.length"
                  >
                    <li
                      class="lh-ProductList-bgListItem js-lh-ProductList-bgListItem"
                      v-for="(color, indexColor) in product.colors"
                      :key="product.id + indexColor"
                      :style="getJpgImagePath(product, indexColor)"
                    ></li>
                  </ul>
                </div>
                <div class="lh-ProductList-infos">
                  <ul class="lh-ProductList-infosList">
                    <li class="lh-ProductList-infosListItem">
                      <span class="lh-ProductList-listItemTitle"
                        >LHight #{{ product.id }}</span
                      >
                    </li>
                    <li class="lh-ProductList-infosListItem">
                      {{ product.colorName }}
                    </li>
                    <li class="lh-ProductList-infosListItem">
                      <span class="lh-Tooltip-trigger"
                        >Version {{ product.version }}</span
                      >
                      <span class="lh-Tooltip-content" data-position="topright">
                        Outre les couleurs, il existe deux versions dans
                        lesquels la disposition de la main gauche (formant le
                        «H») diffère.
                        <br />
                        <br />
                        <div v-if="!product.version != 0">
                          Version 0: dos de la main.
                        </div>
                        <div v-else>
                          Version 1: main retournée, paume de face.
                        </div>
                      </span>
                    </li>
                    <li class="lh-ProductList-infosListItem">
                      <span
                        class="lh-ProductList-infosListItem--price"
                        v-if="!product.isSold"
                        >{{ product.price }}</span
                      >
                      <span class="lh-ProductList-infosListItem--price" v-else
                        >VENDUE</span
                      >
                    </li>
                    <li class="lh-ProductList-infosListItem">
                      <span class="lh-ProductList-link">Voir la lampe</span>
                    </li>
                  </ul>
                </div>
              </router-link>
            </swiper-slide>
            <div
              v-show="computedProducts.length > 2"
              class="lh-ProductLinked-galleryPagination js-lh-ProductLinked-galleryPagination swiper-pagination"
              slot="pagination"
            ></div>
          </swiper>
          <div
            class="lh-ProductLinked-galleryPaginationLineContainer"
            v-show="computedProducts.length > 2"
          >
            <span
              class="js-lh-ProductLinked-galleryPaginationLine lh-ProductLinked-galleryPaginationLine"
            ></span>
          </div>
        </div>
        <div class="lh-ButtonContainer">
          <router-link class="lh-ProductLinkedList-link" to="/boutique"
            >Voir toutes les lampes</router-link
          >
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import { imagePath } from "@/mixins/imagePath.js";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
import Mediaqueries from "@/utlis/mediaqueries";

export default {
  name: "ProductLinkedList",
  components: {
    Swiper,
    SwiperSlide,
  },
  data: () => {
    return {
      wireColor: [],
      version: [],
      isSold: [false],
      color: [],
      swiperOption: {
        slidesPerView: 2,
        breakpointsInverse: true,
        slideToClickedSlide: true,
        freeMode: true,
        freeModeSticky: true,
        spaceBetween: 100,
        mousewheel: {
          forceToAxis: true,
        },
        breakpoints: {
          // when window width is >= 320px
          320: {
            slidesPerView: 1,
            spaceBetween: (window.innerWidth / 100) * 4.4,
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 1.1,
            spaceBetween: 30,
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 1.5,
            spaceBetween: 40,
          },
          768: {
            slidesPerView: 1.5,
            spaceBetween: (window.innerWidth / 100) * 5.5,
          },
          1024: {
            slidesPerView: 1.6,
            spaceBetween: (window.innerWidth / 100) * 5.5,
          },
          1280: {
            slidesPerView: 2,
            spaceBetween: (window.innerWidth / 100) * 4.4,
          },
          1680: {
            slidesPerView: 2.2,
            spaceBetween: (window.innerWidth / 100) * 4.4,
          },
        },
        pagination: {
          el: ".js-lh-ProductLinked-galleryPagination",
          clickable: true,
          renderBullet: function (index, className) {
            return (
              '<span class="' + className + '">0' + (index + 1) + "</span>"
            );
          },
        },
        keyboard: {
          enabled: true,
        },
      },
    };
  },
  mixins: [imagePath],
  computed: {
    computedProducts() {
      const allProducts = this.$store.state.products;
      const activeProduct = this.$store.getters.product(this.$route.params.id);

      return allProducts.filter((item) => {
        if (activeProduct.id != item.id) {
          return (
            ((this.wireColor.length === 0 ||
              this.wireColor.includes(item.wireColor)) &&
              (this.version.length === 0 || this.version == item.version) &&
              (this.isSold.length === 0 ||
                this.isSold == String(item.isSold))) ||
            this.color.length === 0 ||
            this.color == String(item.colors)
          );
        }
      });
    },
  },

  mounted() {
    this.animItem();
    this.initZIndex();
    this.version = this.$store.getters.product(this.$route.params.id).version;
    this.color = this.$store.getters.product(this.$route.params.id).colors;

    if (this.computedProducts.length <= 2) {
      // console.log(this.swiperOption.breakpoints)
      // this.swiperOption.breakpoints[1680].slidesPerView = '6'
      // // console.log(this.swiperOption.breakpoints[1680])
      // // this.$refs.swiperProductLinked.$swiper.destroy()
      // // this.$refs.swiperProductLinked.$swiper.init()
    }
  },

  created() {
    window.addEventListener("scroll", this.handleScroll, { passive: true });
  },

  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  methods: {
    getJpgImagePath(product, index) {
      let extension = "jpg";
      if (document.body.classList.contains("webp")) {
        extension = "webp";
      }
      const img = require(`@/assets/images/colors/${product.colors[index]}.${extension}`);
      return `background-image: url(${img})`;
    },

    handleScroll() {
      const products = document.querySelectorAll(".js-lh-ProductList-listItem");

      products.forEach((product) => {
        const productColorsImgList = product.querySelectorAll(
          ".js-lh-ProductList-bgListItem"
        );

        productColorsImgList.forEach((img) => {
          const getPercent = Math.round(
            (img.getBoundingClientRect().top * 100) / window.innerHeight
          );

          img.style.backgroundPosition = `50% ${getPercent}%`;
        });
      });
    },

    animItem() {
      setTimeout(() => {
        gsap.to(".js-lh-ProductList-listItem", {
          autoAlpha: 0,
          y: "80px",
        });
        ScrollTrigger.batch(".js-lh-ProductList-listItem", {
          start: "top 80%",
          onEnter: (batch) =>
            gsap.to(batch, { autoAlpha: 1, y: 0, stagger: 0.2 }),
        });
      }, 0);
    },

    initZIndex() {
      const productCount = document.querySelectorAll(
        ".js-lh-ProductList-listItem"
      );

      for (var i = 0; i < productCount.length; i++) {
        productCount[i].style.zIndex = productCount.length + 1 - i;
      }
    },

    isOdd(index) {
      if (index % 2) {
        return true;
      }
      return false;
    },

    slideChanged() {
      let defaultMargin = 55;
      let delta = 35;

      if (Mediaqueries.isMinMedium()) {
        defaultMargin = 75;
        delta = 25;
      }
      document.querySelector(
        ".js-lh-ProductLinked-galleryPaginationLine"
      ).style.left = `${
        defaultMargin + delta * this.$refs.swiper.$swiper.activeIndex
      }px`;
    },

    toggleNight(e, product) {
      e.preventDefault();
      product.isNightOn = !product.isNightOn;
    },
  },
};
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
@import "@/components/productlist/_index.scss";
@import "@/components/productlinkedlist/_index.scss";
</style>
