<template>
  <section
    class="lh-ProductDesc js-lh-gallery-scroll"
    itemscope
    itemtype="https://schema.org/Product"
  >
    <div class="lh-Wrapper">
      <div
        class="lh-Wrapper-box lh-Wrapper-box--big lh-Wrapper-box--gray"
        :class="[
          isZoomed ? 'lh-Wrapper-box--full' : '',
          isNightMode ? 'lh-Wrapper-box--dark' : '',
        ]"
      >
        <swiper
          ref="swiper"
          :options="swiperOption"
          @slideChange="slideChanged"
          class="lh-Product-gallery swiper-container"
        >
          <swiper-slide
            class="lh-Product-galleryListItem"
            v-for="productImg in product.images"
            :key="productImg.index"
          >
            <div class="swiper-zoom-container" @click="zoomChanged">
              <picture class="lh-Product-galleryPicture">
                <source
                  :srcset="makeWebpImagesPath(product, productImg)"
                  type="image/webp"
                />
                <source
                  :srcset="makePngImagesPath(product, productImg)"
                  type="image/png"
                />
                <img
                  class="lh-Product-galleryImg"
                  :alt="`LHight # ${product.id}`"
                  :data-src="makePngImagesPath(product, productImg)"
                  loading="lazy"
                  itemprop="image"
                />
              </picture>
            </div>
          </swiper-slide>
          <div
            v-show="product.images.length > 1"
            class="lh-Product-galleryPagination js-lh-Product-galleryPagination swiper-pagination"
            slot="pagination"
          ></div>
        </swiper>
        <span
          v-show="product.images.length > 1"
          class="js-lh-Product-galleryPaginationLine lh-Product-galleryPaginationLine"
        ></span>
        <p class="lh-Product-depo">
          Photos non contractuelles - Modèles déposés
        </p>
        <ul class="lh-Breadcrumb">
          <li class="lh-Breadcrumb-item">
            <router-link class="lh-Breadcrumb-link" to="/">Accueil</router-link>
          </li>
          <li class="lh-Breadcrumb-item">
            <router-link class="lh-Breadcrumb-link" to="/boutique"
              >Boutique</router-link
            >
          </li>
          <li class="lh-Breadcrumb-item">
            <a class="lh-Breadcrumb-link" href="#">{{ product.name }}</a>
          </li>
        </ul>
      </div>
      <div class="lh-Wrapper-box lh-Wrapper-box--small">
        <div class="lh-Wrapper-boxContent">
          <h1
            class="js-lh-Product-boxContentAnimate lh-Product-title"
            itemprop="name"
          >
            {{ product.name }}
          </h1>
          <div class="lh-Product-description">
            <p
              v-html="product.description"
              class="js-lh-Product-boxContentAnimate"
              itemprop="description"
            ></p>
            <br />
            <ul class="lh-Product-descriptionList">
              <li
                class="lh-Product-descriptionListItem js-lh-Product-boxContentAnimate"
              >
                <svg class="lh-Product-descriptionListItemSvg">
                  <use xlink:href="#color"></use>
                </svg>
                <p>
                  <strong>Couleur :&nbsp;</strong>
                  <span itemprop="color">{{ product.colorName }}</span
                  >.
                </p>
              </li>
              <li
                class="lh-Product-descriptionListItem lh-Product-descriptionListItem--version js-lh-Product-boxContentAnimate"
              >
                <svg class="lh-Product-descriptionListItemSvg">
                  <use xlink:href="#version"></use>
                </svg>
                <p>
                  <strong>Version :</strong> {{ product.version }}.
                  <svg class="lh-Product-descriptionListItemInfo">
                    <use xlink:href="#question"></use>
                  </svg>
                  <span class="lh-Product-descriptionListItemTooltip">
                    Outre les couleurs, il existe deux versions dans lesquels la
                    disposition de la main gauche (formant le «H») diffère.
                    <br />
                    <br />
                    <span v-if="product.version == 0">
                      Version 0 : dos de la main.
                    </span>
                    <span v-else>
                      Version 1 : main retournée, paume de face.
                    </span>
                  </span>
                </p>
              </li>
              <li
                class="lh-Product-descriptionListItem js-lh-Product-boxContentAnimate"
              >
                <svg class="lh-Product-descriptionListItemSvg">
                  <use xlink:href="#plug"></use>
                </svg>
                <p>
                  <strong>Fil alimentation :</strong> {{ product.wireColor }},
                  1m12 avec interrupteur.
                </p>
              </li>
              <!-- <li
                class="
                  lh-Product-descriptionListItem
                  js-lh-Product-boxContentAnimate
                "
              >
                <svg class="lh-Product-descriptionListItemSvg">
                  <use xlink:href="#material"></use>
                </svg>
                <p><strong>Matière:</strong> PLA+ RoHS.</p>
              </li> -->
              <li
                class="lh-Product-descriptionListItem js-lh-Product-boxContentAnimate"
              >
                <svg class="lh-Product-descriptionListItemSvg">
                  <use xlink:href="#bulb"></use>
                </svg>
                <p><strong>Format douille :</strong> E14 (LED fournie).</p>
              </li>
            </ul>
          </div>

          <div
            v-if="!product.isSold"
            itemprop="offers"
            itemscope
            itemtype="https://schema.org/Offer"
          >
            <p
              class="js-lh-Product-boxContentAnimate lh-Product-price"
              itemprop="price"
              :content="product.price"
            >
              {{ product.price }}
              <span itemprop="priceCurrency" content="EUR">€</span>
            </p>
            <span
              class="js-lh-Product-sticky"
              :class="isSticky ? `lh-Product-sticky--fake` : ``"
            ></span>
            <div
              class="lh-Product-sticky"
              :class="[
                isSticky ? `lh-Product-sticky--fixed` : ``,
                isStickyEnd ? `lh-Product-sticky--end` : ``,
                isExtraSticky ? `lh-Product-sticky--extra` : ``,
              ]"
            >
              <button
                @click="buyNow"
                class="lh-Button js-lh-Product-boxContentAnimate"
              >
                <link itemprop="availability" href="InStock" />
                Acheter
              </button>
            </div>
            <form
              class="lh-PaypalForm"
              action="https://www.paypal.com/cgi-bin/webscr"
              method="post"
              target="_top"
            >
              <input type="hidden" name="cmd" value="_s-xclick" />
              <input
                type="hidden"
                name="hosted_button_id"
                :value="product.paypalId"
              />
              <input
                class="js-lh-PaypalButton"
                type="image"
                src="https://www.paypalobjects.com/fr_FR/FR/i/btn/btn_buynow_LG.gif"
                border="0"
                name="submit"
                alt="PayPal, le réflexe sécurité pour payer en ligne"
              />
              <img
                alt=""
                border="0"
                src="https://www.paypalobjects.com/fr_FR/i/scr/pixel.gif"
                width="1"
                height="1"
              />
            </form>
          </div>
          <div v-else>
            <p
              class="js-lh-Product-boxContentAnimate lh-Product-price"
              itemprop="offers"
              itemscope
              itemtype="https://schema.org/Offer"
            >
              <link itemprop="availability" href="OutOfStock" />
              VENDUE
            </p>
          </div>
          <div class="lh-Product-Share">
            <p class="js-lh-Product-boxContentAnimate">Partager :</p>
            <ul class="lh-Product-ShareList">
              <li
                class="lh-Product-ShareListItem js-lh-Product-boxContentAnimate"
              >
                <a
                  class="lh-Product-ShareLink"
                  href="#"
                  @click="fbShare"
                  aria-label="Partager sur Facebook"
                >
                  <svg class="lh-Product-ShareLinkIcon">
                    <use xlink:href="#facebookShare"></use>
                  </svg>
                </a>
              </li>
              <li
                class="lh-Product-ShareListItem js-lh-Product-boxContentAnimate"
              >
                <a
                  class="lh-Product-ShareLink"
                  href="mailto:hello@lhight.fr?subject=LHight#0&amp;body=Hello&nbsp;Stan,"
                  aria-label="Partager par email"
                >
                  <svg class="lh-Product-ShareLinkIcon">
                    <use xlink:href="#mailShare"></use>
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import { imagePath } from "@/mixins/imagePath.js";
import { gsap, Power2 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Mediaqueries from "@/utlis/mediaqueries";

gsap.registerPlugin(ScrollTrigger);

export default {
  name: "productDesc",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      product: this.$store.getters.product(this.$route.params.id),
      swiperOption: {
        slidesPerView: 1,
        centeredSlides: false,
        breakpointsInverse: true,
        slideToClickedSlide: true,
        direction: "vertical",
        freeMode: true,
        freeModeSticky: true,
        mousewheel: {
          eventsTarged: ".js-lh-gallery-scroll",
          forceToAxis: true,
        },
        pagination: {
          el: ".js-lh-Product-galleryPagination",
          clickable: true,
          renderBullet: function (index, className) {
            return (
              '<span class="' + className + '">0' + (index + 1) + "</span>"
            );
          },
        },
        zoom: {
          maxRatio: 1.3,
        },
        keyboard: {
          enabled: true,
        },
      },
      isZoomed: false,
      isSticky: false,
      isStickyEnd: false,
      isExtraSticky: false,
      isNightMode: false,
    };
  },

  mixins: [imagePath],

  created() {
    window.addEventListener("scroll", this.handleScroll, { passive: true });
  },

  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  mounted() {
    this.animate();
  },

  methods: {
    buyNow() {
      document.querySelector(".js-lh-PaypalButton").click();
    },

    animate() {
      gsap.set(".js-lh-Product-boxContentAnimate", {
        autoAlpha: 0,
        y: "+=20",
      });
      ScrollTrigger.batch(".js-lh-Product-boxContentAnimate", {
        start: "top bottom",
        onEnterBack: (batch) =>
          gsap.to(batch, {
            autoAlpha: 1,
            y: 0,
            stagger: 0.2,
            overwrite: true,
          }),
        onEnter: (batch) =>
          gsap.to(batch, { autoAlpha: 1, y: 0, stagger: 0.2 }),
      });
    },

    slideChanged() {
      let defaultMargin = 90;
      let delta = 60;
      let slideIndex = this.$refs.swiper.$swiper.activeIndex;

      if (Mediaqueries.isMinMedium()) {
        defaultMargin = 105;
        delta = 90;
      }
      document.querySelector(
        ".js-lh-Product-galleryPaginationLine"
      ).style.bottom = `${
        defaultMargin + delta * this.$refs.swiper.$swiper.activeIndex
      }px`;

      if (slideIndex >= 4) {
        this.isNightMode = true;
      } else {
        this.isNightMode = false;
      }

      this.$refs.swiper.$swiper.zoom.out();
    },

    zoomChanged() {
      this.$refs.swiper.$swiper.zoom.toggle();
      const tl = new gsap.timeline();

      if (this.$refs.swiper.$swiper.zoom.scale > 1) {
        this.isZoomed = true;
        tl.to(
          ".js-lh-Product-galleryPagination, .js-lh-Product-galleryPaginationLine,.lh-Breadcrumb, .lh-Product-depo",
          0.3,
          { autoAlpha: 0, ease: Power2.easeOut }
        );
        this.$refs.swiper.$swiper.mousewheel.disable();
      } else {
        this.isZoomed = false;
        tl.to(
          ".js-lh-Product-galleryPagination, .js-lh-Product-galleryPaginationLine,.lh-Breadcrumb, .lh-Product-depo",
          0.6,
          { autoAlpha: 1, ease: Power2.easeOut }
        );
        this.$refs.swiper.$swiper.mousewheel.enable();
      }
    },

    handleScroll() {
      if (document.scrollingElement.scrollTop != 0) {
        this.$refs.swiper.$swiper.mousewheel.disable();
      } else {
        this.$refs.swiper.$swiper.mousewheel.enable();
      }
      const buyButton = document.querySelector(".js-lh-Product-sticky");
      if (buyButton && !Mediaqueries.isMinMedium()) {
        let handlerFired;
        const containerTop = buyButton.getBoundingClientRect().top - 20;

        const end =
          document.querySelector(".lh-ProductLinked").getBoundingClientRect()
            .top - 170;
        if (containerTop <= 0) {
          if (!handlerFired) {
            handlerFired = 1;
            this.isSticky = true;
          }
        }
        if (containerTop > 0) {
          handlerFired = 0;
          this.isSticky = false;
        }
        if (end <= 0) {
          this.isStickyEnd = true;
        } else {
          this.isStickyEnd = false;
        }
        if (
          !document
            .querySelector(".lh-Header")
            .classList.contains("lh-Header--up")
        ) {
          this.isExtraSticky = true;
        } else {
          this.isExtraSticky = false;
        }
      }
    },

    fbShare() {
      const url = window.location.href;
      window.open(
        "https://www.facebook.com/sharer/sharer.php?u=" + url,
        "facebook-share-dialog",
        "width=800,height=600"
      );
      return false;
    },
  },
};
</script>

<style lang="scss">
@import "@/components/productdesc/_index.scss";
</style>
