<template>
  <main class="js-lh-Product lh-Product" style="overflow: hidden; width: 100%">
    <ProductDesc></ProductDesc>
    <!-- <LHTitle :titleText="titleMensurations" isSmall /> -->
    <ProductPrevNext></ProductPrevNext>
    <Mensurations></Mensurations>
    <LHTitle :titleText="titleFAQ" isSmall />
    <Faq></Faq>
    <LHTitle :titleText="titleLinked" isSmall />
    <ProductLinkedList></ProductLinkedList>
  </main>
</template>

<script>
import ProductDesc from "@/components/productdesc/ProductDesc.vue";
import Mensurations from "@/components/mensurations/Mensurations.vue";
import ProductPrevNext from "@/components/productprevnext/ProductPrevNext.vue";
import Faq from "@/components/faq/Faq.vue";
import LHTitle from "@/components/lhtitle/LHTitle.vue";
import ProductLinkedList from "@/components/productlinkedlist/ProductLinkedList.vue";

export default {
  name: "product",
  components: {
    ProductDesc,
    ProductPrevNext,
    Mensurations,
    Faq,
    LHTitle,
    ProductLinkedList,
  },
  data: () => {
    return {
      // titleMensurations: "Les dimensions",
      titleFAQ: "Questions fréquentes",
      titleLinked: "Dans le même style...",
    };
  },
};
</script>
