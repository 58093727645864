<template>
  <section class="lh-ProductPrevNext">
    <div class="lh-Wrapper">
      <div class="lh-ButtonsContainer">
        <router-link
          :to="{ name: 'product', params: { id: product.id - 1 } }"
          class="lh-ProductPrevNext-link lh-ProductPrevNext-link--prev"
          :class="
            product.id - 1 >= 0 ? '' : 'lh-ProductPrevNext-link--disabled'
          "
          ><span>Voir la LHight&nbsp;</span>précédente</router-link
        >
        <router-link
          :to="{ name: 'product', params: { id: product.id + 1 } }"
          class="lh-ProductPrevNext-link"
          :class="
            product.id + 1 <= 12 ? '' : 'lh-ProductPrevNext-link--disabled'
          "
          ><span>Voir la LHight&nbsp;</span>suivante</router-link
        >
      </div>
    </div>
  </section>
</template>

<script>
import { imagePath } from "@/mixins/imagePath.js";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default {
  name: "productPrevNex",
  data() {
    return {
      product: this.$store.getters.product(this.$route.params.id),
    };
  },

  mixins: [imagePath],

  mounted() {
    // this.animate();
  },

  methods: {
    // animate() {
    //   gsap.set(".js-lh-Product-boxContentAnimate", {
    //     autoAlpha: 0,
    //     y: "+=20",
    //   });
    //   ScrollTrigger.batch(".js-lh-Product-boxContentAnimate", {
    //     start: "top bottom",
    //     onEnterBack: (batch) =>
    //       gsap.to(batch, {
    //         autoAlpha: 1,
    //         y: 0,
    //         stagger: 0.2,
    //         overwrite: true,
    //       }),
    //     onEnter: (batch) =>
    //       gsap.to(batch, { autoAlpha: 1, y: 0, stagger: 0.2 }),
    //   });
    // },
  },
};
</script>

<style lang="scss">
@import "@/components/productprevnext/_index.scss";
</style>
