<template>
  <section class="lh-Mensurations">
    <div id="triggerMensurations"></div>
    <div class="lh-Wrapper">
      <div class="lh-Wrapper-box lh-Wrapper-box--small">
        <div class="lh-Wrapper-boxContent">
          <div
            class="lh-Mensurations-mensurations js-lh-Mensurations-boxContentAnimate"
          >
            <div class="lh-Mensurations-mensurations-box">
              <h1
                class="lh-Mensurations-title js-lh-mensurations-ContentAnimate"
              >
                Dimensions
              </h1>
              <ul class="lh-Mensurations-list">
                <li
                  class="lh-Mensurations-listItem js-lh-mensurations-ContentAnimate"
                >
                  Hauteur&nbsp;:<span class="lh-Mensurations-dots"></span
                  ><span class="lh-Mensurations-value">43cm</span>
                </li>
                <li
                  class="lh-Mensurations-listItem js-lh-mensurations-ContentAnimate"
                >
                  Diamètre&nbsp;:<span class="lh-Mensurations-dots"></span
                  ><span class="lh-Mensurations-value">21cm</span>
                </li>
                <li
                  class="lh-Mensurations-listItem js-lh-mensurations-ContentAnimate"
                >
                  Poids&nbsp;:<span class="lh-Mensurations-dots"></span
                  ><span class="lh-Mensurations-value">400gr<sup>*</sup></span>
                </li>
              </ul>
              <p class="lh-Mensurations-info js-lh-mensurations-ContentAnimate">
                <sup>*</sup>Sans ampoule
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="lh-Wrapper-box lh-Wrapper-box--big lh-Wrapper-box--gray">
        <div class="lh-Wrapper-boxContent">
          <div class="lh-ThreeDesc lh-ThreeProduct">
            <div class="lh-ThreeDesc-bg"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default {
  name: "mensurations",
  data() {
    return {};
  },

  mounted() {
    this.mensurationsAnim();
  },

  destroyed() {
    ScrollTrigger.getAll().forEach((t) => t.kill());
  },

  methods: {
    mensurationsAnim() {
      const timeline = new gsap.timeline();
      timeline.from(".js-lh-Mensurations-boxContentAnimate", 1, {
        y: "100%",
        autoAlpha: 0,
        ease: "Power2.ease",
      });
      timeline.staggerFromTo(
        ".js-lh-mensurations-ContentAnimate",
        0.6,
        {
          autoAlpha: 0,
          ease: "Power3.easeNone",
          y: "+=20",
        },
        {
          autoAlpha: 1,
          ease: "Power3.easeNone",
          y: "0",
        },
        0.2
      );

      ScrollTrigger.create({
        trigger: "#triggerMensurations",
        animation: timeline,
        id: "lh-Mensuration",
        scrub: 1,
        start: "top 70%",
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/components/mensurations/_index.scss";
</style>
